import { useFormik } from 'formik';
import { Navigate } from 'react-router-dom';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { useAuth, FormInput, Loader, AuthLayout } from '@omnigenbiodata/ui';
import { MESSAGES } from '../../../../core/constants/forms.constants';
import ROUTES from '../../../../core/constants/routes.constants';
import Buttons from '../../../../components/forms/Buttons';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object().shape({
  email: yup.string().required(MESSAGES.emailRequired),
});

function ResetPassword() {
  const { t } = useTranslation('portal');
  const { forgotPasswordHasError, isBusy, forgotPassword, forgotPasswordEmail } = useAuth();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values: any) => {
      forgotPassword(values.email);
    },
  });

  return (
    <>
      {forgotPasswordEmail && <Navigate to={ROUTES.authSubmitResetPassword} />}
      <AuthLayout
        title={t('resetPassword.title')}
        text={t('resetPassword.text')}
        hasError={forgotPasswordHasError}
        errorText={t('alerts.resetPasswordFailed.text')}
        errorTitle={t('alerts.resetPasswordFailed.title')}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box mb={4}>
            <FormInput
              error={formik.errors.email}
              name="email"
              label={t('forms.email')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.email}
              value={formik.values.email}
            />
          </Box>
          <Buttons onForward={formik.submitForm} forwardLabel={t('buttons.confirm')} backButton />
        </form>
      </AuthLayout>
      <Loader isVisible={isBusy} label={t('loaders.check') as string} />
    </>
  );
}

export default ResetPassword;
