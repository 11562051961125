import { BrowserRouter as Router } from 'react-router-dom';
import { FlagsProvider } from 'react-feature-flags';
import Scenes from './scenes';
import { ScrollToTop, EnvFlag, AuthProvider } from '@omnigenbiodata/ui';
import ROUTES from './core/constants/routes.constants';
import ENV from './core/constants/environment.constants';
import { FEATURE_FLAGS } from './core/constants/features.constants';

function App() {
  return (
    <FlagsProvider value={FEATURE_FLAGS}>
      <Router>
        <ScrollToTop />
        <AuthProvider authPath={ROUTES.auth} postLoginPath={ROUTES.authSplash} postLogoutPath={ROUTES.authSignOut}>
          <Scenes />
          <EnvFlag env={ENV.ENVIRONMENT as any} version={ENV.VERSION} />
        </AuthProvider>
      </Router>
    </FlagsProvider>
  );
}

export default App;
