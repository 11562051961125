import { createSlice } from '@reduxjs/toolkit';
import { PracticeState } from './types';
import { StoreStatus } from '../../core/types/common.types';
import { getSiteEnrollmentsThunk, getSiteWithdrawalsThunk, getSiteParticipantsThunk } from './thunks';

// Initial State
const initialState: PracticeState = {
  enrolments: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
  },
  withdrawals: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
  },
  participants: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
  },
};

// Slice
export const practiceSlice = createSlice({
  name: 'practice',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getSiteEnrollmentsThunk.pending.type, (state) => {
      state.enrolments.status = StoreStatus.BUSY;
    });
    builder.addCase(getSiteEnrollmentsThunk.fulfilled.type, (state, action: any) => {
      state.enrolments.status = StoreStatus.IDLE;
      state.enrolments.response = action.payload;
      state.enrolments.errors = null;
    });
    builder.addCase(getSiteEnrollmentsThunk.rejected.type, (state, { payload }: any) => {
      state.enrolments.status = StoreStatus.ERROR;
      state.enrolments.errors = payload;
      state.enrolments.response = null;
    });
    builder.addCase(getSiteWithdrawalsThunk.pending.type, (state) => {
      state.withdrawals.status = StoreStatus.BUSY;
    });
    builder.addCase(getSiteWithdrawalsThunk.fulfilled.type, (state, action: any) => {
      state.withdrawals.status = StoreStatus.IDLE;
      state.withdrawals.response = action.payload;
      state.withdrawals.errors = null;
    });
    builder.addCase(getSiteWithdrawalsThunk.rejected.type, (state, { payload }: any) => {
      state.withdrawals.status = StoreStatus.ERROR;
      state.withdrawals.errors = payload;
      state.withdrawals.response = null;
    });
    builder.addCase(getSiteParticipantsThunk.pending.type, (state) => {
      state.participants.status = StoreStatus.BUSY;
    });
    builder.addCase(getSiteParticipantsThunk.fulfilled.type, (state, action: any) => {
      state.participants.status = StoreStatus.IDLE;
      state.participants.response = action.payload;
      state.participants.errors = null;
    });
    builder.addCase(getSiteParticipantsThunk.rejected.type, (state, { payload }: any) => {
      state.participants.status = StoreStatus.ERROR;
      state.participants.errors = payload;
      state.participants.response = null;
    });
  },
});

// Action creators
export const { reset } = practiceSlice.actions;
export * from './thunks';
export * from './selectors';
export * from './types';
export default practiceSlice.reducer;
