/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';
import { PageWrapper } from '@omnigenbiodata/ui';
import { useStyles } from './component.styles';

interface SplashLayoutProps {
  children: ReactNode;
}

function SplashLayout({ children }: SplashLayoutProps) {
  const classes = useStyles();

  return (
    <PageWrapper isShaded>
      <div css={classes.content}>{children}</div>{' '}
    </PageWrapper>
  );
}

export default SplashLayout;
