import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useAuth, FormInput, Loader, AuthLayout } from '@omnigenbiodata/ui';
import ROUTES from '../../../../core/constants/routes.constants';
import { AUTH_CHALLENGES } from '../../../../core/constants/aws.constants';
import { Navigate } from 'react-router-dom';
import Buttons from '../../../../components/forms/Buttons';

import { MESSAGES, PASSWORD_REGEX } from '../../../../core/constants/forms.constants';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object().shape({
  firstName: yup.string().required(MESSAGES.firstNameRequired),
  lastName: yup.string().required(MESSAGES.lastNameRequired),
  password: yup.string().required(MESSAGES.passwordRequired).matches(PASSWORD_REGEX, MESSAGES.passwordFormat),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], MESSAGES.passwordNoMatch)
    .required(MESSAGES.passwordConfirmRequired),
});

function NewPassword() {
  const { t } = useTranslation('portal');
  const { hasError, isBusy, completeNewPassword, challengeName } = useAuth();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
      firstName: '',
      lastName: '',
    },
    validationSchema,
    onSubmit: (values: any) => {
      completeNewPassword(values);
    },
  });

  if (
    !isBusy &&
    (challengeName === AUTH_CHALLENGES.MFA_SETUP || challengeName === AUTH_CHALLENGES.SOFTWARE_TOKEN_MFA)
  ) {
    return <Navigate to={ROUTES.authMfa} />;
  }

  return (
    <AuthLayout
      title={t('newPassword.title')}
      text={t('newPassword.text')}
      hasError={hasError}
      errorText={t('alerts.regFailed.text')}
      errorTitle={t('alerts.regFailed.title')}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box mb={4}>
          <FormInput
            error={formik.errors.firstName}
            name="firstName"
            label={t('forms.firstName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.firstName}
            value={formik.values.firstName}
          />
          <FormInput
            error={formik.errors.lastName}
            name="lastName"
            label={t('forms.lastName')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.lastName}
            value={formik.values.lastName}
          />
          <FormInput
            error={formik.errors.password}
            name="password"
            label={t('forms.newPassword')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.password}
            value={formik.values.password}
            type="password"
            startAdornment={<RiLockPasswordLine />}
          />
          <FormInput
            error={formik.errors.confirmPassword}
            name="confirmPassword"
            label={t('forms.confirmPassword')}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.confirmPassword}
            value={formik.values.confirmPassword}
            type="password"
            startAdornment={<RiLockPasswordLine />}
          />
        </Box>
        <Buttons backButton onForward={formik.submitForm} forwardLabel={t('buttons.confirm')} />
      </form>
      <Loader isVisible={isBusy} label={t('loaders.check') as string} />
    </AuthLayout>
  );
}

export default NewPassword;
