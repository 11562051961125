import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../../../core/constants/routes.constants';
import { reset as resetUser } from '../../../../store/user';
import { reset as resetPractice } from '../../../../store/practice';
import { useAppDispatch } from '../../../../store';

function Logout() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(resetUser());
    dispatch(resetPractice());
  }, [dispatch]);
  return <Navigate to={ROUTES.root} />;
}

export default Logout;
