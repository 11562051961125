/* Types  */
export interface GraphQlErrorLocation {
  line: number;
  column: number;
  sourceName: string | null;
}

export interface GraphQlError {
  path: string[];
  data: string | null;
  errorType: string;
  errorInfo: string | null;
  locations: GraphQlErrorLocation[];
  message: string;
}

export enum LoginMethods {
  EMAIL = 'EMAIL',
}

export enum UserType {
  SITE = 'SITE',
  STUDY = 'STUDY',
}

export type ODSRow = {
  Code: string;
  Name: string;
  AddressLine1: string;
  AddressLine2?: string;
  Country: string;
  Town: string;
  County: string;
  Postcode: string;
  Initiated: boolean;
};

export type User = {
  firstName: string;
  surname: string;
  practices: ODSRow[];
  userType: UserType;
};

export type Patient = {
  NHSNumber: string;
  status?: string;
};

export type GetUserResponse = {
  data?: { getPortalUser: User };
  errors?: GraphQlError[];
};

export type GetEnrolmentsResponse = {
  data?: {
    getSiteEnrollments: {
      enrolmentCount: number;
    };
  };
  errors?: GraphQlError[];
};

export type GetWithdrawalsResponse = {
  data?: {
    getSiteWithdrawals: {
      withdrawalCount: number;
    };
  };
  errors?: GraphQlError[];
};

export type GetParticipantsResponse = {
  data?: {
    getSiteParticipants: {
      participants: Patient[];
    };
  };
  errors?: GraphQlError[];
};
