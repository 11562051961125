import { Outlet } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../core/constants/routes.constants';
import { useAppSelector } from '../../store';
import { hasSuccessSelector } from '../../store/user';

function PortalScene() {
  const hasSuccess = useAppSelector(hasSuccessSelector);

  if (!hasSuccess) {
    return <Navigate to={ROUTES.root} />;
  }

  return <Outlet />;
}

export default PortalScene;
