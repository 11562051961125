import { css } from '@emotion/react';

export const useStyles = () => {
  return {
    content: css`
      flex-grow: 1;
      align-content: center;
    `,
  };
};
