/* Validation Rules */
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

export const MESSAGES = {
  emailInvalid: 'Invalid E-mail address',
  emailRequired: 'E-mail is required',
  firstNameRequired: 'First Name is required',
  lastNameRequired: 'Surname is required',
  idRequired: 'Participant ID is required',
  otpRequired: 'One-time password is required',
  passwordConfirmRequired: 'Confirm new password is required',
  passwordRequired: 'Password is required',
  passwordFormat:
    'Must contain at least 8 characters, one uppercase, one lowercase, one number and one special character',
  passwordNoMatch: "Passwords don't match",
};

const FORMS = {
  PASSWORD_REGEX,
  MESSAGES,
};
export default FORMS;
