import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { SiteParticipants } from './types';

export const enrolmentsErrorSelector = (state: RootState): any | null => state.practice.enrolments.errors || null;

export const enrolmentsBusySelector = (state: RootState): boolean =>
  state.practice.enrolments.status === StoreStatus.BUSY;

export const enrolmentsResponseSelector = (state: RootState): { enrolmentCount: number } | null =>
  state.practice.enrolments.response || null;

export const enrolmentsSuccessSelector = (state: RootState): boolean =>
  state.practice.enrolments.response ? true : false;

export const withdrawalsErrorSelector = (state: RootState): any | null => state.practice.withdrawals.errors || null;

export const withdrawalsBusySelector = (state: RootState): boolean =>
  state.practice.withdrawals.status === StoreStatus.BUSY;

export const withdrawalsResponseSelector = (state: RootState): { withdrawalCount: number } | null =>
  state.practice.withdrawals.response || null;

export const withdrawalsSuccessSelector = (state: RootState): boolean =>
  state.practice.withdrawals.response ? true : false;

export const participantsResponseSelector = (state: RootState): SiteParticipants | null =>
  state.practice.participants.response || null;

export const participantsErrorSelector = (state: RootState): any | null => state.practice.participants.errors || null;

export const participantsBusySelector = (state: RootState): boolean =>
  state.practice.withdrawals.status === StoreStatus.BUSY;
