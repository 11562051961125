import { RootState } from '../index';
import { StoreStatus } from '../../core/types/common.types';
import { User } from '../../core/api/portal.types';

export const hasErrorSelector = (state: RootState): boolean => (state.user.errors && state.user.errors ? true : false);

export const isBusySelector = (state: RootState): boolean => state.user.status === StoreStatus.BUSY;

export const responseSelector = (state: RootState): User | null => state.user.response || null;

export const hasSuccessSelector = (state: RootState): boolean => (state.user.response ? true : false);
