import { useEffect } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../../../store';
import MainLayout from '../../../../layouts/MainLayout';
import Typography from '@mui/material/Typography';
import { responseSelector } from '../../../../store/user';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DataPoint from '../../../../components/DataPoint';
import { theme } from '@omnigenbiodata/ui';
import { Flags } from 'react-feature-flags';
import { FEATURES } from '../../../../core/constants/features.constants';
import { ENROLMENT_URL, STUDY_EMAIL, STUDY_TEL } from '../../../../core/constants/content.constants';
import { useTranslation } from 'react-i18next';
import { BiCheckCircle } from 'react-icons/bi';
import {
  getSiteEnrollmentsThunk,
  getSiteWithdrawalsThunk,
  getSiteParticipantsThunk,
  enrolmentsResponseSelector,
  withdrawalsResponseSelector,
  participantsResponseSelector,
  enrolmentsErrorSelector,
  withdrawalsErrorSelector,
  reset,
} from '../../../../store/practice';
import ROUTES from '../../../../core/constants/routes.constants';

function HomeScene() {
  const user = useAppSelector(responseSelector);
  const { odsCode } = useParams<any>();
  const currentPractice = user?.practices.find(({ Code }) => Code === odsCode);
  const enrolments = useAppSelector(enrolmentsResponseSelector);
  const withdrawals = useAppSelector(withdrawalsResponseSelector);
  const participants = useAppSelector(participantsResponseSelector);
  const enrolmentsError = useAppSelector(enrolmentsErrorSelector);
  const withdrawalsError = useAppSelector(withdrawalsErrorSelector);
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const { t } = useTranslation('portal');

  useEffect(() => {
    if (currentPractice) {
      dispatch(getSiteEnrollmentsThunk(currentPractice.Code));
      dispatch(getSiteWithdrawalsThunk(currentPractice.Code));
      dispatch(getSiteParticipantsThunk(currentPractice.Code));
    }
  }, [dispatch, currentPractice]);

  useEffect(() => {
    return () => dispatch(reset());
  }, [dispatch]);

  const changeSite = () => {
    navigateTo(ROUTES.portal);
  };

  return user ? (
    !currentPractice ? (
      <Navigate to={ROUTES.portal} />
    ) : (
      <MainLayout>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h4" component="h1" align="left" py={2} paragraph>
            {t('home.title', { name: user.firstName })}
          </Typography>
          {user.practices.length > 1 && (
            <Box alignSelf="end">
              <Button variant="contained" color="primary" size="large" sx={{ mb: 2 }} onClick={changeSite}>
                {t('buttons.changeSite')}
              </Button>
            </Box>
          )}
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Paper elevation={2} sx={{ height: '100%', p: 4 }}>
              <Typography fontSize="1.1rem" mb={1} variant="subtitle1">
                {t('home.sections.gp')}
              </Typography>
              <Typography textTransform="uppercase" variant="body2">
                {currentPractice.Name}
              </Typography>
              <Typography textTransform="uppercase" variant="body2">
                {currentPractice.AddressLine1}
              </Typography>
              {currentPractice.AddressLine2 && (
                <Typography textTransform="uppercase" variant="body2">
                  {currentPractice.AddressLine2}
                </Typography>
              )}
              <Typography textTransform="uppercase" variant="body2">
                {currentPractice.Town}
              </Typography>
              <Typography textTransform="uppercase" variant="body2">
                {currentPractice.Postcode}
              </Typography>
              {currentPractice.Initiated && (
                <Box mt={2} display="flex" alignItems="center">
                  <BiCheckCircle size={25} color={theme.palette.success.main} />
                  <Typography
                    fontSize="1.1rem"
                    fontWeight="bold"
                    ml={1}
                    color={theme.palette.success.main}
                    variant="body1"
                  >
                    {t('home.sections.initiated')}
                  </Typography>
                </Box>
              )}
            </Paper>
          </Grid>
          <Flags authorizedFlags={[FEATURES.TOTAL_ENROLMENTS]}>
            <Grid item xs={6} sm={4}>
              <DataPoint
                count={enrolments?.enrolmentCount.toString()}
                type={'Enrolments'}
                errors={enrolmentsError}
                data={participants?.enrolled}
              />
            </Grid>
          </Flags>
          <Flags authorizedFlags={[FEATURES.TOTAL_ENROLMENTS]}>
            <Grid item xs={6} sm={4}>
              <DataPoint
                errors={withdrawalsError}
                count={withdrawals?.withdrawalCount.toString()}
                type={'Withdrawals'}
                data={participants?.withdrawn}
                color={theme.palette.error.main}
              />
            </Grid>
          </Flags>
          <Grid item xs={12} sm={6}>
            <Paper elevation={2} sx={{ height: '100%', p: 4 }}>
              <Typography fontSize="1.1rem" variant="subtitle1">
                {t('home.sections.url.title')}
              </Typography>
              <Typography my={1} variant="body2">
                {t('home.sections.url.text')}
              </Typography>
              <Link underline="hover" target="_blank" href={`https://${ENROLMENT_URL}${currentPractice.Code}`}>
                <Typography fontWeight="bold" color={theme.palette.info.dark} variant="subtitle1">
                  {ENROLMENT_URL}
                  {currentPractice.Code}
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper elevation={2} sx={{ height: '100%', p: 4 }}>
              <Typography fontSize="1.1rem" variant="subtitle1">
                {t('home.sections.contact')}
              </Typography>
              <Link target="_blank" href={`tel:${STUDY_TEL}`} underline="none">
                <Typography my={1} variant="h6">
                  <strong>{STUDY_TEL}</strong>
                </Typography>
              </Link>
              <Link target="_blank" href={`mailto:${STUDY_EMAIL}`} underline="hover">
                <Typography fontWeight="bold" color={theme.palette.info.dark} variant="h6">
                  {STUDY_EMAIL}
                </Typography>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </MainLayout>
    )
  ) : null;
}

export default HomeScene;
