export const ROOT = `/`;
export const AUTH_BASE = `${ROOT}auth/`;
export const PORTAL_BASE = `${ROOT}portal/`;

export const ROUTES = {
  root: ROOT,
  auth: AUTH_BASE,
  authMfa: `${AUTH_BASE}mfa`,
  authNewPassword: `${AUTH_BASE}newpassword`,
  authResetPassword: `${AUTH_BASE}resetpassword`,
  authSubmitResetPassword: `${AUTH_BASE}submitresetpassword`,
  authSignOut: `${AUTH_BASE}logout`,
  authSplash: `${AUTH_BASE}splash`,
  portal: PORTAL_BASE,
  site: `${PORTAL_BASE}:odsCode`,
};

export default ROUTES;
