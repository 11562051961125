import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import { PageWrapper, DMUKLogo, useAuth } from '@omnigenbiodata/ui';
import Typography from '@mui/material/Typography';
import { RiLogoutBoxRLine } from 'react-icons/ri';
import Button from '@mui/material/Button';
import ROUTES from '../../core/constants/routes.constants';
import { useTranslation } from 'react-i18next';

interface MainLayoutProps {
  children: any;
  title?: string | null;
}

function MainLayout({ children, title }: MainLayoutProps) {
  const { t } = useTranslation('portal');
  const { signOut } = useAuth();
  return (
    <PageWrapper isShaded>
      <Box display="flex" justifyContent="space-between">
        <Link to={ROUTES.root}>
          <DMUKLogo size="sm" />
        </Link>
        <Button size="large" onClick={signOut} startIcon={<RiLogoutBoxRLine />}>
          {t('buttons.signOut')}
        </Button>
      </Box>
      {title && (
        <Typography variant="h3" component="h1" align="center" mt={4} paragraph>
          {title}
        </Typography>
      )}
      {children}
    </PageWrapper>
  );
}

export default MainLayout;
