import { RootState } from '../../store';
import { UserType } from '../api/portal.types';
import { StoreStatus } from '../types/common.types';

export const MOCK_USER = {
  firstName: 'Test',
  surname: 'User',
  odsCode: 'M84002',
  practices: [
    {
      Code: 'M46TY',
      Name: 'Vale of Red Horse',
      AddressLine1: 'Old School',
      AddressLine2: 'Market Square',
      Town: 'Warwick',
      Postcode: 'CV35 GLP',
      Initiated: true,
    },
  ],
  userType: UserType.SITE,
};

export const MOCK_MULTI_SITE_USER = {
  firstName: 'Test',
  surname: 'User',
  odsCode: 'M84002',
  practices: [
    {
      Code: 'M46TY',
      Name: 'VALE OF RED HORSE',
      AddressLine1: 'Old School',
      AddressLine2: 'Market Square',
      Town: 'Warwick',
      Postcode: 'CV35 GLP',
      Initiated: true,
    },
    {
      Code: 'M56TY',
      Name: 'MEON MEDICAL CENTRE',
      AddressLine1: 'Old School',
      AddressLine2: 'Market Square',
      Town: 'Warwick',
      Postcode: 'CV35 GLP',
      Initiated: true,
    },
    {
      Code: 'M58TY',
      Name: 'WILLESDEN GREEN',
      AddressLine1: 'Old School',
      AddressLine2: 'Market Square',
      Town: 'Warwick',
      Postcode: 'CV35 GLP',
      Initiated: true,
    },
  ],
  userType: UserType.SITE,
};

export const MOCK_PARTICIPANTS = {
  enrolled: [
    { NHSNumber: '1234', status: 'Enrolled' },
    { NHSNumber: '1235', status: 'Enrolled' },
    { NHSNumber: '1236', status: 'Enrolled' },
  ],
  withdrawn: [
    { NHSNumber: '2234', status: 'Complete Withdrawal' },
    { NHSNumber: '2235', status: 'Sharing Only Withdrawal' },
    { NHSNumber: '2236', status: 'Sharing Only Withdrawal' },
  ],
};

export const MOCK_SITE_DATA = {
  enrolments: {
    errors: null,
    status: StoreStatus.IDLE,
    response: {
      enrolmentCount: 12,
    },
  },
  withdrawals: {
    errors: null,
    status: StoreStatus.IDLE,
    response: {
      withdrawalCount: 4,
    },
  },
  participants: {
    errors: null,
    status: StoreStatus.IDLE,
    response: MOCK_PARTICIPANTS,
  },
};

export const MOCK_ROOT_STATE: RootState = {
  user: {
    errors: null,
    status: StoreStatus.IDLE,
    response: null,
  },
  practice: {
    enrolments: {
      errors: null,
      status: StoreStatus.IDLE,
      response: null,
    },
    withdrawals: {
      errors: null,
      status: StoreStatus.IDLE,
      response: null,
    },
    participants: {
      errors: null,
      status: StoreStatus.IDLE,
      response: null,
    },
  },
};
