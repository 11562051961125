import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import userReducer from './user';
import practiceReducer from './practice';

const store = configureStore({
  reducer: {
    user: userReducer,
    practice: practiceReducer,
  },
  middleware: (getDefaultMiddleware: (arg0: { serializableCheck: boolean }) => any) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
    }),
  ],
});
export type RootState = ReturnType<typeof store.getState>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => any = useDispatch; // Export a hook that can be reused

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
