import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import {
  GetParticipantsResponse,
  GetEnrolmentsResponse,
  GetWithdrawalsResponse,
  GraphQlError,
  Patient,
} from '../../core/api/portal.types';

export const getSiteEnrollmentsThunk = createAsyncThunk<
  // Return type of the payload creator
  GetEnrolmentsResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('enrolments/get', async (odsCode, thunkApi) => {
  const response: GetEnrolmentsResponse = await portalApi.getSiteEnrollments(odsCode);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getSiteEnrollments as GetEnrolmentsResponse;
});

export const getSiteWithdrawalsThunk = createAsyncThunk<
  // Return type of the payload creator
  GetWithdrawalsResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('withdrawals/get', async (odsCode, thunkApi) => {
  const response: GetWithdrawalsResponse = await portalApi.getSiteWithdrawals(odsCode);

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getSiteWithdrawals as GetWithdrawalsResponse;
});

export const getSiteParticipantsThunk = createAsyncThunk<
  // Return type of the payload creator
  GetParticipantsResponse,
  // First argument to the payload creator
  string,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('participants/get', async (odsCode, thunkApi) => {
  const enrolledResponse: GetParticipantsResponse = await portalApi.getSiteParticipants(odsCode);
  const completeResponse: GetParticipantsResponse = await portalApi.getSiteParticipants(odsCode, 'Complete');
  const sharingOnlyResponse: GetParticipantsResponse = await portalApi.getSiteParticipants(odsCode, 'SharingOnly');
  const sharingAndCollectionResponse: GetParticipantsResponse = await portalApi.getSiteParticipants(
    odsCode,
    'SharingAndCollection',
  );
  const collectionOnlyResponse: GetParticipantsResponse = await portalApi.getSiteParticipants(
    odsCode,
    'CollectionOnly',
  );
  const errors =
    enrolledResponse.errors ||
    completeResponse.errors ||
    sharingAndCollectionResponse.errors ||
    sharingOnlyResponse.errors ||
    collectionOnlyResponse.errors;
  if (errors) {
    return thunkApi.rejectWithValue(errors);
  }
  const participants: any = {
    enrolled: [],
    withdrawn: [],
  };
  participants.enrolled = enrolledResponse.data?.getSiteParticipants.participants.map((item: Patient) => {
    item.status = 'Enrolled';
    return item;
  });

  completeResponse.data?.getSiteParticipants.participants.forEach((item: Patient) => {
    item.status = 'Complete Withdrawal';
    participants.withdrawn.push(item);
  });
  sharingOnlyResponse.data?.getSiteParticipants.participants.forEach((item: Patient) => {
    item.status = 'Sharing Only Withdrawal';
    participants.withdrawn.push(item);
  });
  collectionOnlyResponse.data?.getSiteParticipants.participants.forEach((item: Patient) => {
    item.status = 'Collection Only Withdrawal';
    participants.withdrawn.push(item);
  });
  sharingAndCollectionResponse.data?.getSiteParticipants.participants.forEach((item: Patient) => {
    item.status = 'Sharing and Collection Withdrawal';
    participants.withdrawn.push(item);
  });

  return participants as GetParticipantsResponse;
});
