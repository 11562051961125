import { Route, Routes } from 'react-router-dom';
import { AuthRoute } from '@omnigenbiodata/ui';
import ROUTES from '../core/constants/routes.constants';

import AuthScene from './AuthScene';
import Login from './AuthScene/scenes/Login';
import MultiFactor from './AuthScene/scenes/MultiFactor';
import NewPassword from './AuthScene/scenes/NewPassword';
import ResetPassword from './AuthScene/scenes/ResetPassword';
import SubmitResetPassword from './AuthScene/scenes/SubmitResetPassword';
import Logout from './AuthScene/scenes/Logout';
import SplashScene from './AuthScene/scenes/SplashScene';
import PortalScene from './PortalScene';
import SiteSelectionScene from './PortalScene/scenes/SiteSelectionScene';
import HomeScene from './PortalScene/scenes/HomeScene';

function Scenes() {
  return (
    <Routes>
      <Route path={ROUTES.auth} element={<AuthScene />}>
        <Route path={ROUTES.auth} element={<Login />} />
        <Route path={ROUTES.authMfa} element={<MultiFactor />} />
        <Route path={ROUTES.authNewPassword} element={<NewPassword />} />
        <Route path={ROUTES.authResetPassword} element={<ResetPassword />} />
        <Route path={ROUTES.authSubmitResetPassword} element={<SubmitResetPassword />} />
        <Route path={ROUTES.authSplash} element={<SplashScene />} />
        <Route path={ROUTES.authSignOut} element={<Logout />} />
      </Route>
      <Route path={ROUTES.portal} element={<AuthRoute path={ROUTES.portal} element={PortalScene} />}>
        <Route path={ROUTES.portal} element={<SiteSelectionScene />} />
        <Route path={ROUTES.site} element={<HomeScene />} />
      </Route>
      <Route path="*" element={<AuthRoute path={ROUTES.authSplash} element={SplashScene} />} />
    </Routes>
  );
}

export default Scenes;
