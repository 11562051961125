import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import ROUTES from '../../../../core/constants/routes.constants';
import { useAppSelector } from '../../../../store';
import { responseSelector } from '../../../../store/user';
import MainLayout from '../../../../layouts/MainLayout';
import { debounce } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { ButtonRow, theme, FormInput } from '@omnigenbiodata/ui';
import { useTranslation } from 'react-i18next';

function PortalScene() {
  const response = useAppSelector(responseSelector);
  const navigate = useNavigate();
  const { t } = useTranslation('portal');

  useEffect(() => {
    if (response?.practices.length === 1) {
      navigate(ROUTES.site.replace(':odsCode', response.practices[0].Code));
    }
  }, [navigate, response]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      searchTerm: '',
      practices: response?.practices,
      selected: null,
    },
    onSubmit: (values) => {
      if (values.selected) {
        navigate(ROUTES.site.replace(':odsCode', values.selected));
      }
    },
  });

  const changeSite = (odsCode: string) => () => {
    formik.setFieldValue('selected', odsCode);
  };

  const searchPractices = useMemo(
    () =>
      debounce((searchTerm: string) => {
        if (searchTerm.length > 2) {
          const filtered = response?.practices.filter(({ Name }) => Name.match(searchTerm.toUpperCase()));
          formik.setFieldValue('practices', filtered);
        } else formik.setFieldValue('practices', response?.practices);
      }, 500),
    [formik, response],
  );

  return (
    <MainLayout>
      <Card sx={{ m: 4 }}>
        <CardHeader
          title={
            <Typography variant="h3" component="h1" align="center" mt={4}>
              {t('siteSelection.title')}
            </Typography>
          }
        />
        <CardContent sx={{ mx: { md: '20%' } }}>
          <FormInput
            label={t('forms.search')}
            type="text"
            name={`searchTerm`}
            data-testid="searchTerm"
            error={formik.errors.searchTerm}
            touched={formik.touched.searchTerm}
            onChange={(e: any) => {
              formik.handleChange(e);
              searchPractices(e.currentTarget.value);
            }}
            onBlur={formik.handleBlur}
            value={formik.values.searchTerm}
            startAdornment={<AiOutlineSearch fontSize={25} />}
          />
          <List
            sx={{
              mb: 3,
              minHeight: '300px',
              overflowY: 'auto',
              p: 0,
              border: `1px solid ${theme.palette.grey[500]}`,
            }}
          >
            {formik.values.practices?.map((practice) => (
              <ListItemButton
                selected={practice.Code === formik.values.selected}
                key={practice.Code}
                data-testid={`ods-${practice.Code}`}
                onClick={changeSite(practice.Code)}
              >
                <ListItemText>{practice.Name}</ListItemText>
              </ListItemButton>
            ))}
          </List>
          <ButtonRow
            buttonSize="large"
            showBack={false}
            isDisabled={!formik.values.selected}
            onForward={formik.handleSubmit}
            forwardLabel={t('buttons.confirm') as string}
            forwardColor="primary"
          />
        </CardContent>
      </Card>
    </MainLayout>
  );
}

export default PortalScene;
