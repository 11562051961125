import { Loader } from '@omnigenbiodata/ui';
import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import ROUTES from '../../../../core/constants/routes.constants';
import SplashLayout from '../../../../layouts/SplashLayout';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { getUserThunk, isBusySelector, hasSuccessSelector, responseSelector } from '../../../../store/user';

function SplashScene() {
  const dispatch = useAppDispatch();
  const isBusy = useAppSelector(isBusySelector);
  const hasSuccess = useAppSelector(hasSuccessSelector);
  const user = useAppSelector(responseSelector);

  useEffect(() => {
    if (!user) dispatch(getUserThunk());
  }, [dispatch, user]);

  if (!isBusy && hasSuccess && user) {
    return <Navigate to={ROUTES.portal} />;
  }

  return (
    <SplashLayout>
      <Loader isVisible label="Checking status" />
    </SplashLayout>
  );
}

export default SplashScene;
