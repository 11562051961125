import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { theme } from '@omnigenbiodata/ui';
import { GraphQlError, Patient } from '../../core/api/portal.types';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import { BiErrorCircle } from 'react-icons/bi';
import { CSVLink } from 'react-csv';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';

interface DataPointProps {
  color?: string | null;
  type: string;
  data?: Patient[];
  count?: string;
  errors?: GraphQlError[];
}

const DataPoint = ({ type, data, color, errors, count }: DataPointProps) => {
  const { t } = useTranslation('portal');
  return (
    <Paper
      elevation={2}
      sx={{
        height: '100%',
        p: 2,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box ml="auto">
        {data ? (
          <CSVLink
            headers={[
              { label: 'NHS Number', key: 'nhsNumber' },
              { label: 'Status', key: 'status' },
            ]}
            filename={`${type} DMUK ${new Date().toDateString()}.csv`}
            data={data}
            target="_blank"
            data-testid={`${type.toLowerCase()}-download`}
          >
            <HiOutlineDocumentDownload color={theme.palette.primary.main} size={30} />
          </CSVLink>
        ) : (
          <HiOutlineDocumentDownload color={theme.palette.primary.lighter} size={30} />
        )}
      </Box>
      {count && (
        <Typography
          fontWeight={500}
          fontSize="4rem"
          data-testid={`total-${type.toLowerCase()}`}
          color={color || theme.palette.success.main}
        >
          {count}
        </Typography>
      )}
      <Box>
        {!count && !errors && <CircularProgress />}
        {!count && errors && (
          <Tooltip title={errors[0].message} arrow>
            <Button>
              <BiErrorCircle size={60} />
            </Button>
          </Tooltip>
        )}
      </Box>
      <Typography py={2} variant="subtitle2">
        {t('home.sections.totals', { type: type })}
      </Typography>
    </Paper>
  );
};

export default DataPoint;
