import { API as MOCK_AMPLIFY_API } from '../__mocks__/aws-amplify';
import { API as AMPLIFY_API } from 'aws-amplify';
import {
  GetUserResponse,
  GetEnrolmentsResponse,
  GetWithdrawalsResponse,
  GetParticipantsResponse,
} from './portal.types';
import {
  getUserQuery,
  getSiteEnrollmentsQuery,
  getSiteWithdrawalsQuery,
  getSiteParticipantsQuery,
} from '../graphql/queries';

const API = process.env.REACT_APP_USE_MOCKS === 'true' ? MOCK_AMPLIFY_API : AMPLIFY_API;

const portalApi = {
  getUser: async (): Promise<GetUserResponse> => {
    try {
      return (await API.graphql({
        query: getUserQuery,
      })) as GetUserResponse;
    } catch (error) {
      return error as GetUserResponse;
    }
  },
  getSiteEnrollments: async (odsCode: string): Promise<GetEnrolmentsResponse> => {
    try {
      return (await API.graphql({
        query: getSiteEnrollmentsQuery,
        variables: {
          odsCode: odsCode,
        },
      })) as GetEnrolmentsResponse;
    } catch (error) {
      return error as GetEnrolmentsResponse;
    }
  },
  getSiteWithdrawals: async (odsCode: string): Promise<GetWithdrawalsResponse> => {
    try {
      return (await API.graphql({
        query: getSiteWithdrawalsQuery,
        variables: {
          odsCode: odsCode,
        },
      })) as GetWithdrawalsResponse;
    } catch (error) {
      return error as GetWithdrawalsResponse;
    }
  },
  getSiteParticipants: async (odsCode: string, level?: string): Promise<GetParticipantsResponse> => {
    try {
      return (await API.graphql({
        query: getSiteParticipantsQuery,
        variables: {
          odsCode: odsCode,
          withdrawalLevel: level,
        },
      })) as GetParticipantsResponse;
    } catch (error) {
      return error as GetParticipantsResponse;
    }
  },
};

export default portalApi;
