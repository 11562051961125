import { useNavigate, Navigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import { RiLockPasswordLine } from 'react-icons/ri';
import { useAuth, FormInput, Loader, AuthLayout } from '@omnigenbiodata/ui';
import Buttons from '../../../../components/forms/Buttons';

import ROUTES from '../../../../core/constants/routes.constants';
import { MESSAGES, PASSWORD_REGEX } from '../../../../core/constants/forms.constants';
import { useTranslation } from 'react-i18next';

const validationSchema = yup.object().shape({
  code: yup.string().required(MESSAGES.emailRequired),
  password: yup.string().required(MESSAGES.passwordRequired).matches(PASSWORD_REGEX, MESSAGES.passwordFormat),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], MESSAGES.passwordNoMatch)
    .required(MESSAGES.passwordConfirmRequired),
});

function SubmitResetPassword() {
  const { t } = useTranslation('portal');
  const navigateFn = useNavigate();

  const {
    forgotPasswordSubmitHasError,
    isBusy,
    forgotPasswordEmail,
    forgotPasswordSubmitHasSuccess,
    forgotPasswordSubmit,
    forgotPasswordDeliveryDetails,
    reset,
  } = useAuth();
  const formik = useFormik({
    initialValues: {
      email: forgotPasswordEmail,
      code: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values: any) => {
      forgotPasswordSubmit(values);
    },
  });
  const handleCancel = () => {
    reset(); //TODO add to context
    navigateFn(ROUTES.auth);
  };

  return (
    <>
      {!forgotPasswordEmail && <Navigate to={ROUTES.authResetPassword} />}
      {forgotPasswordSubmitHasSuccess && <Navigate to={ROUTES.auth} />}
      <AuthLayout
        title={t('submitResetPassword.title')}
        text={
          forgotPasswordDeliveryDetails &&
          t('submitResetPassword.line1', {
            destination: forgotPasswordDeliveryDetails.Destination,
          })
        }
        text2={t('submitResetPassword.line2')}
        hasError={forgotPasswordSubmitHasError}
        errorText={t('alerts.recoveryFailed.text')}
        errorTitle={t('alerts.recoveryFailed.title')}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box mb={4}>
            <FormInput
              error={formik.errors.code}
              name="code"
              label={t('forms.code')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.code}
              value={formik.values.code}
            />
            <FormInput
              error={formik.errors.password}
              name="password"
              label={t('forms.newPassword')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.password}
              value={formik.values.password}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
            <FormInput
              error={formik.errors.confirmPassword}
              name="confirmPassword"
              label={t('forms.confirmPassword')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              touched={formik.touched.confirmPassword}
              value={formik.values.confirmPassword}
              type="password"
              startAdornment={<RiLockPasswordLine />}
            />
          </Box>
          <Buttons
            onForward={formik.submitForm}
            forwardLabel={t('buttons.confirm')}
            backLabel={t('buttons.cancel') as string}
            onBack={handleCancel}
          />
        </form>
        <Loader isVisible={isBusy} label={t('loaders.check') as string} />
      </AuthLayout>
    </>
  );
}

export default SubmitResetPassword;
