export const getUserQuery = `
query getPortalUserQuery {
    getPortalUser {
        firstName
        surname
        practices {
            Code
            Name
            Country
            AddressLine1
            AddressLine2
            Town
            County
            Postcode
            Initiated
        }
        userType
    },
}
`;
export const getSiteEnrollmentsQuery = `
query getSiteEnrollmentsQuery($odsCode: String!) {
    getSiteEnrollments(odsCode: $odsCode) {
        enrolmentCount
    },
}
`;
export const getSiteWithdrawalsQuery = `
query getSiteWithdrawalsQuery($odsCode: String!) {
    getSiteWithdrawals(odsCode: $odsCode) {
        withdrawalCount
    },
}
`;
export const getSiteParticipantsQuery = `
query getSiteParticipantsQuery($odsCode: String!, $withdrawalLevel: WithdrawalLevel) {
    getSiteParticipants(odsCode: $odsCode, withdrawalLevel: $withdrawalLevel) {
        participants {
            nhsNumber
        }
    },
}
`;
