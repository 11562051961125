import { createAsyncThunk } from '@reduxjs/toolkit';
import portalApi from '../../core/api/portal.api';
import { GetUserResponse, GraphQlError } from '../../core/api/portal.types';

export const getUserThunk = createAsyncThunk<
  // Return type of the payload creator
  GetUserResponse,
  // First argument to the payload creator
  undefined,
  // Types for ThunkAPI
  {
    extra: {
      jwt: string;
    };
    rejectValue: GraphQlError[];
  }
>('user/get', async (values, thunkApi) => {
  const response: GetUserResponse = await portalApi.getUser();

  if (response.errors) {
    return thunkApi.rejectWithValue(response.errors);
  }
  return response.data?.getPortalUser as GetUserResponse;
});
